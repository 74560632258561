:root {
  --code-id-color: #28afb0;
  --code-variable-color: #7b287d;
  --code-string-color: #4357ad;
  --code-comment-color: #999;
  --code-keyword-color: #96adc8;
  --code-function-color: #0470e2;
  --code-error-color: #d14;
}

.example-editor {
  background-color: var(--indent-bg-color);
  padding-top: 1.5rem;
}

.example-editor .ace_editor {
  line-height: 1.2rem !important;
}

.example-editor .ace_editor .ace_cursor {
  color: var(--main-accent-lighter-color);
}

.example-editor .ace_editor .ace_hidden-cursors .ace_cursor {
  color: #0000;
}

.example-editor .ace_editor .ace_gutter {
  background-color: #0000;
  width: 1rem;
}

.example-editor .ace_editor .ace_gutter-active-line {
  background-color: #0000;
}

.example-editor .ace_editor .ace_comment {
  color: var(--code-comment-color);
  font-style: italic;
}

.example-editor .ace_editor .ace_section {
  color: #666;
}

.example-editor .ace_editor .ace_message {
  color: var(--code-id-color);
  font-weight: normal;
}

.example-editor .ace_editor .ace_string {
  color: var(--code-string-color);
}

.example-editor .ace_editor .ace_number, .example-editor .ace_editor .ace_symbol {
  color: var(--code-keyword-color);
}

.example-editor .ace_editor .ace_variable {
  color: var(--code-variable-color);
}

.example-editor .ace_editor .ace_function {
  color: var(--code-function-color);
}

.example-editor .ace_editor .ace_invalid {
  color: red;
  background-color: #ffe5e5;
}

.example-editor .ace_editor .ace_marker-layer .ace_selection {
  background: #b5d5ff;
}

.example-editor .ace_editor .ace_multiselect .ace_selection.ace_start {
  box-shadow: 0 0 3px #fff;
}

.example-editor .ace_editor.ace_nobold .ace_line > span {
  font-weight: normal !important;
}

.example-editor .ace_editor .ace_marker-layer .ace_step {
  background: #fcff00;
}

.example-editor .ace_editor .ace_marker-layer .ace_stack {
  background: #a4e565;
}

.example-editor .ace_editor .ace_marker-layer .ace_bracket {
  border: 1px solid silver;
  margin: -1px 0 0 -1px;
}

.example-editor .ace_editor .ace_marker-layer .ace_selected-word {
  background: #fafaff;
  border: 1px solid #c8c8fa;
}

.example-editor .ace_editor .ace_error {
  background: #ffa5a5 !important;
}
/*# sourceMappingURL=index.5c8efff5.css.map */
